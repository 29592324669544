const headerData = (vm, title) => {
    const headerVal = []
    // headerVal[0] = vm.reportHeadData.orgName
    // headerVal[1] = vm.reportHeadData.address
    // headerVal[2] = vm.reportHeadData.heading
    headerVal[0] = title
    headerVal[1] = ''
    // headerVal[5] = getHeadParam(vm)
    headerVal[2] = ''
    return headerVal
}

const excelBody = (vm) => {
    const listContractor = []
    vm.dataArray.forEach((element, index) => {
        const myObj = {}
        myObj[vm.$t('globalTrans.sl_no')] = vm.$n(index + 1)
        myObj[vm.$t('teaGardenConfig.tea_garden_name')] = vm.$i18n.locale === 'en' ? element.tea_garden_name_en : element.tea_garden_name_bn
        myObj[vm.$t('teaGardenConfig.garden_address')] = vm.$i18n.locale === 'en' ? element.garden_address_en : element.garden_address_bn
        myObj[vm.$t('teaGardenConfig.garden_location')] = vm.getDistrictName(element.district_id)
        myObj[vm.$t('teaGardenConfig.company_name')] = vm.getTeaCompanyName(element.tea_company_id)
        myObj[vm.$t('teaGardenConfig.registration_no')] = element.registration_no_en
        myObj[vm.$t('teaGardenConfig.garden_class')] = vm.$i18n.locale === 'en' ? element.garden_class_en : element.garden_class_bn
        myObj[vm.$t('teaGardenConfig.total_land')] = vm.$n(element.total_land ? element.total_land : 0)
        myObj[vm.$t('teaGardenConfig.lease_duration')] = vm.$n(element.lease_duration ? element.lease_duration : 0)

        listContractor.push(myObj)
    })
    return listContractor
}

// const getHeadParam = (vm) => {
//     let head = ''
//     if (Object.values(vm.titleData).length > 0) {
//       head = vm.$t('globalTrans.fiscalYear') + ' : ' + (parseInt(vm.search.fiscal_year_id) ? vm.getFiscalYearName(parseInt(vm.search.fiscal_year_id)) : vm.$t('globalTrans.all'))
//       head = head + ', ' + vm.$t('globalTrans.division') + ' : ' + (parseInt(vm.titleData.division_id) ? vm.getDivisionName(parseInt(vm.titleData.division_id)) : vm.$t('globalTrans.all'))
//       head = head + ', ' + vm.$t('globalTrans.district') + ' : ' + (parseInt(vm.titleData.district_id) ? vm.getDistrictName(parseInt(vm.titleData.district_id)) : vm.$t('globalTrans.all'))
//       if (vm.titleData.upazila_id > 0) {
//         head = head + ', ' + vm.$t('globalTrans.upazila') + ' : ' + (parseInt(vm.titleData.upazila_id) ? vm.getUpazilaName(parseInt(vm.titleData.upazila_id)) : vm.$t('globalTrans.all'))
//       }
//       if (vm.titleData.city_corporation_id > 0) {
//         head = head + ', ' + vm.$t('org_pro.city_corporation') + ' : ' + (parseInt(vm.titleData.city_corporation_id) ? vm.getCityCorpName(parseInt(vm.titleData.city_corporation_id)) : vm.$t('globalTrans.all'))
//       }
//       if (vm.titleData.office_id > 0) {
//         head = head + ', ' + vm.$t('externalUser.office_name') + ' : ' + (parseInt(vm.titleData.office_id) ? vm.getOfficeName(parseInt(vm.titleData.office_id)) : vm.$t('globalTrans.all'))
//       }
//     }
//     return head
//   }

export default {
    headerData,
    excelBody
}
